'use client';

import { useEffect, useState } from 'react';

export const debounce = (callback: (...args: unknown[]) => unknown, waitTime: number) => {
	let timeoutId: NodeJS.Timeout;
	return () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(callback, waitTime);
	};
};

const useResize = () => {
	const [size, setSize] = useState({
		width: 0,
		height: 0,
	});

	const handleSetWidth = () => {
		setSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		handleSetWidth();
		const handler = debounce(handleSetWidth, 200);

		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
	}, []);

	const breakpoint = {
		xxl: size.width >= 1536,
		xl: size.width >= 1280,
		lg: size.width >= 1024,
		md: size.width >= 768,
		sm: size.width >= 640,
	};

	return {
		...size,
		...breakpoint,
	};
};

export default useResize;
