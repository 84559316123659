import { useEffect, useState } from 'react';

type ScrollDirection = 'up' | 'down' | null;

export const useScrollDirection = () => {
	const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null);
	const [prevScrollPos, setPrevScrollPos] = useState<number>(0); // Initialize with 0

	const handleScroll = () => {
		if (typeof window !== 'undefined') {
			const currentScrollPos = window.scrollY;

			// Check if at the top of the page
			if (currentScrollPos === 0) {
				setScrollDirection('up');
			} else if (currentScrollPos > prevScrollPos) {
				setScrollDirection('down');
			} else {
				setScrollDirection('up');
			}

			setPrevScrollPos(currentScrollPos);
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [prevScrollPos]);

	return scrollDirection;
};
